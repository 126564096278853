/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

		/* ADD JS STYLES */
		$('header.banner').css({"position": "fixed"});
		/* END ADD JS STYLES */

		$('html').addClass('js_active');

		/* PARALLAX EFFECT */
		function process_parallaxes() {
			var st = $(window).scrollTop(),
				top_header = $('.masthead'),
				header_title = $('body:not(.who-we-fund) .masthead h1, body:not(.who-we-fund) .masthead p.header-content'),
				bread_crumbs = $('body:not(.who-we-fund) .masthead p#breadcrumbs');
			top_header.css({'background-position':"50% "+(st/16.1)+"%"});
			header_title.css({'opacity': 1 - (st/325)});
			bread_crumbs.css({'opacity': 0.7 - (st/325)});
		}
		$(window).scroll(process_parallaxes);
		/* END PARALLAX EFFECT */

		/* HEADER SCROLL EFFECT */
		var sticky_navigation_offset_top = 0;
		// Create a true/false variable to manage animations running on EVERY scroll
		var animateHeader = true;

		// Create the function to run on each scroll
		function sticky_navigation(){
		    var scroll_top = $(window).scrollTop();
		        if (scroll_top > sticky_navigation_offset_top) {
		        // First let's add a scrolled class to the whole header, logo, search and mobile menu (they are outside the header for search and mobile overlay)
				$('header.banner, #search-button, .header-logo.brand, .search-overlay, #click-menu').addClass('scrolled');
			} else {
				// Restore order to the elements
				$('header.banner, #search-button, .header-logo.brand, .search-overlay, #click-menu').removeClass('scrolled');
			}
		}
		// Run our function on page load
		sticky_navigation();

		// Run our function on each scroll
		$(window).on('scroll', function() {
			sticky_navigation();
		});
		/* END HEADER SCROLL EFFECT */

		/** SHOW AND HIDE SEARCH & MOBILE MENU **/
		// Sets how large the overlay should be
		function layerInit(){
			var diameterValue = (Math.sqrt( Math.pow($(window).height(), 2) + Math.pow($(window).width(), 2))*3);
			// Sets the height and width to eventual size but makes it invisible with scale 0
			$('.search-overlay, .mobile-menu-overlay').children('span').velocity({
				scaleX: 0,
				scaleY: 0,
				translateZ: 0,
			}, 50).velocity({
				height : diameterValue+'px',
				width : diameterValue+'px',
				top : -(diameterValue/2)+'px',
				left : -(diameterValue/2)+'px',
			}, 0);
		}
		// Runs function
		layerInit();
		// Sets variable to see if overlay is open
		var searchOpen = false;
		// Event trigger for animate out and back in
		$('#search-button').click(function() {
			if (searchOpen) {
				$('#header-search').fadeToggle('fast');
				$('.search-overlay').children('span').velocity({
					translateZ: 0,
					scaleX: 0,
					scaleY: 0,
				}, 300, 'easeOutCubic');
				searchOpen = false;
			} else {
				$('.search-overlay').children('span').velocity({
				translateZ: 0,
				scaleX: 1,
				scaleY: 1,
				}, 500, 'easeInCubic', function() {
					$('#header-search').fadeToggle('slow');
					$('#header-search input[type="text"]').focus();
				});
				// Sets variable now that it's open
				searchOpen = true;
			}
			// Changes spy glass icon to X icon
			$(this).children('i').toggleClass('icon-search').toggleClass('icon-cancel');
			$('.brand').toggleClass('logo-overlay');
			return false;
		});

		// Shake Animation
		function shake(div) {
		    var interval = 100;
			var distance = 10;
			var times = 4;
			$(div).css('position','relative');
			for(var iter = 0; iter < (times + 1); iter++){
				$(div).animate({ left:((iter % 2 === 0 ? distance : distance * -1)) }, interval);
			}
			$(div).animate({ left: 0 },interval);
		}
		// Search Submit
		$('#header-search').submit(function(e) {
			var s = $(this).find('input[type="text"]');
			if (!s.val()) {
				e.preventDefault();
				shake($(this).find('input[type="text"]'));
				$(this).find('input[type="text"]').focus();
			}
		});

		// Sets variable to see if overlay is open
		var mobileOpen = false;
		// Event trigger for animate out and back in
		$('#click-menu').click(function() {
			$(this).toggleClass('mobile-close');
			if (mobileOpen) {
				$('#mobile-menu').fadeToggle('fast');
				$('.mobile-menu-overlay').children('span').velocity({
					translateZ: 0,
					scaleX: 0,
					scaleY: 0,
				}, 500, 'easeOutCubic');
				mobileOpen = false;
			} else {
				$('.mobile-menu-overlay').children('span').velocity({
				translateZ: 0,
				scaleX: 1,
				scaleY: 1,
				}, 300, 'easeInCubic', function() {
					$('#mobile-menu').fadeToggle('slow');
				});
				// Changes variable now that it is open
				mobileOpen = true;
			}
			$('.brand').toggleClass('logo-overlay');
			return false;
		});

		$('.mobile-nav li.menu-item-has-children').click(function(e) {
			$(this).children('ul li').fadeTo("slow", 1);
		});
		/** END SHOW AND HIDE SEARCH & MOBILE MENU **/


		/** ANIMATED ANCHOR LINKS **/
		$('a[href*="#"]:not([href="#"])').click(function(e) {
			if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
				var target = $(this.hash);
				var $this = this;
				target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
				if (target.length) {
					$('html,body').animate({
						scrollTop: (target.offset().top - 100)
					}, 500, function() {
						if(history.pushState) {
							history.pushState(null, null, $this.hash);
						}
						else {
							location.hash = $this.hash;
						}
					});
					e.preventDefault();
				}
			}
		});
		/** END ANIMATED ANCHOR LINKS **/


		/** ANCHOR LINKS OFFSET **/
		// This function lets anchor links go to the right section with the fixed header
		// The function actually applying the offset
		function offsetAnchor() {
		    if(location.hash.length !== 0) {
		        window.scrollTo(window.scrollX, window.scrollY - 100);
		    }
		}

		// This will capture hash changes while on the page
		$(window).on("hashchange", function () {
		    offsetAnchor();
		});

		// This is here so that when you enter the page with a hash,
		// it can provide the offset in that case too. Having a timeout
		// seems necessary to allow the browser to jump to the anchor first.
		window.setTimeout(function() {
		    offsetAnchor();
		}, 1);
		/** END ANCHOR LINKS OFFSET **/

		/** FULL WIDTH ROWS */
		function fullWidthRows() {
			var wrapperClass = '.container', // Bootstrap class
				element = $('.full-width'),
				width = element.closest(wrapperClass).width(),
				spacing = ( $(window).outerWidth() - width ) / 2;
			element.css({
				'margin-left' : - spacing,
				'margin-right' : - spacing,
				'padding-left' : 0,
				'padding-right' : 0,
			});
		}
		$(window).load( fullWidthRows );
		$(window).resize( fullWidthRows );
		
		/** END FULL WIDTH ROWS */

		/* NUMBER TICKER UPON VIEW */
		var numberTickers = $('.ticker');
		if ( numberTickers.length ) {
			numberTickers.counterUp({
				time: 700
			});
		}
		/* END NUMBER TICKER UPON VIEW */

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $(document).ready(function(){
		  $('.featured-awardees').slick({
		    draggable: true,
		    dots: true,
		    arrows: false,
		    slidesToShow: 1,
		    fade: true,
		    speed: 500,
		    cssEase: 'linear',
		    appendDots: $('.featured-awardees')
		  });
		});

		/* SLICKJS CAROUSEL */
		if ($('#homepage-slider').length > 0) {
			var slider = $('#homepage-slider > .wpb_column > .vc_column-inner > .wpb_wrapper');

			slider.slick({
					slide         : '#homepage-slider > .wpb_column > .vc_column-inner > .wpb_wrapper > *',
					autoplay      : true,
					autoplaySpeed : 6000,
					infinite      : true,
					cssEase       : 'ease',
					slidesToShow  : 1,
					slidesToScroll: 1,
					fade          : true,
					mobileFirst   : true,
					arrows				: false,
			});
		}

		/** VIDEO BG */
		var bgndVideo = $("#bgndVideo");
		$(function(){
    		jQuery(bgndVideo).YTPlayer();
		});
		/** VIDEO BG */
		
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_program': {
      init: function() {
      	/** SLICK CAROUSELS */
		$(document).ready(function(){
		  $('.current-awardees .row').slick({
		    draggable: true,
		    dots: true,
		    arrows: false,
		    slidesToShow: 1,
		    centerMode: true,
		    mobileFirst: true,
		    responsive: [{
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }, {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    }, {
                        breakpoint: 1600,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4
                        }
                    }] 
		  });
		});

		/** STICKY SUB-MENU ON SCROLL */
		// Sets up our sub nav sections and their data
		function stickySubNavSetup() {
			// Allow global access of variable data
			//  Creates an array with all anchor links
			window.anchorLinks = $('.program-sub-menu a').map(function() { 
	          return this.href.substr(this.href.indexOf('#')); 
	        }).toArray(); 
			// Creat an empty array to store anchor objects
	        window.anchorObjs = [];
	        for (var i=0; i < anchorLinks.length; i++) {
	        	// Pushes anchor link and position of anchor section to each object
	        	anchorObjs.push({
	        			link : anchorLinks[i],
	        			positionTop : $(anchorLinks[i]).offset().top,
	        			positionBottom : $(anchorLinks[i]).offset().top + $(anchorLinks[i]).outerHeight()
	        			
	        		});
	        }
		}

		// Checks position of scroll and highlights sub program li accordingly
		function stickySubNav() {
			var scrollTop = $(window).scrollTop() + 100;
			var activeAnchor, activeWidth, activeLeft;
			if (scrollTop >= 175) {
				$( '.program-sub-menu-container' ).addClass('fixed');
			} else {
				$( '.program-sub-menu-container' ).removeClass('fixed');
			}
			// When ever the mouse scrolls, we'll loop through the anchor objects to check if we are in it's section
			// and apply an active state if we are.
			scrollTopSub = scrollTop + 60;
			for (var i=0; i < anchorObjs.length; i++) {
				if (scrollTopSub  >= anchorObjs[i].positionTop && scrollTopSub <= anchorObjs[i].positionBottom ) {
					$('a[href="' + anchorObjs[i].link + '"]').parent('li').addClass('active');
					activeAnchor = $('a[href="' + anchorObjs[i].link + '"]').parent('li');
					// This code is for magic underline (unused right now)
					// activeWidth = activeAnchor.width() + 40;
					// activeLeft = activeAnchor.position().left;
					// $('#magic-line').stop().animate({
			  //           left: activeLeft,
			  //           width: activeWidth
			  //       }, 100);
				} else {
					$('a[href="' + anchorObjs[i].link + '"]').parent('li').removeClass('active');
				}
			}
		}
		// Setup data on laod
		$(window).load(function(){ stickySubNavSetup(); });
		// Run function on scroll
		$(window).scroll(function(){ stickySubNav(); });
		/** END STICKY SUB-MENU ON SCROLL */
      }
    },
    'ignite_la': {
      init: function() {
      	$(document).ready(function(){
		  $('.awardee-images').slick({
		    draggable: true,
		    dots: true,
		    arrows: false,
		    slidesToShow: 1,
		    centerMode: true,
		    mobileFirst: true,
		    responsive: [{
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }, {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    }] 
		  });
		});
      }
    },
    'who_we_fund': {
      init: function() {
      	$(document).ready(function(){
      		// Adds anchor link to pagination links
		  $('.pagination a.page-numbers').each(function() {
		  	var $this = $(this);
		  	var href = $this.attr("href"); 
		  	$this.attr("href", href + '#awardee-container');
		  });
		});
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
